<template>
  <div class="bg-color">
    <v-container class="v-container-fluid" fluid>
      <v-row>
        <v-col cols="12" md="3" sm="12" xs="12">
          <div class="content-image-logo">
            <div class="content-logo">
              <img src="@/assets/logos/logo-bg-white.svg" alt="" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="6" xs="12">
          <div class="content-description">
            <p class="txt-description mon-regular">
              {{ texts.footer.description }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="3" sm="6" xs="12">
          <div
            class="
              content-social
              display-flex
              align-items-center
              justify-content-flex-end
            "
          >
            <div class="display-flex align-items-center">
              <img width="8px" src="@/assets/logos/facebook-logo.svg" alt="" />
              <p
                @click="redirectFacebook"
                class="txt-social-media text-underline ml-2 mon-regular"
              >
                Facebook
              </p>
            </div>
            <div class="display-flex align-items-center ml-9">
              <img
                width="11px"
                src="@/assets/logos/instagram-logo.svg"
                alt=""
              />
              <p
                @click="redirectInstagram"
                class="txt-social-media text-underline ml-2 mon-regular"
              >
                Instagram
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <hr class="separator-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="4" xs="12">
          <div class="content-terms">
            <a @click="redirectLink()" class="txt-link mon-regular">
              {{ texts.footer.termsAndConditions }}
            </a>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="4" xs="12">
          <div class="content-all-rights">
            <p class="txt-description mon-regular">
              {{ texts.footer.allRights }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="4" xs="12" class="text-right">
          <div class="content-poweredby">
            <a
              href="https://sofex.com.mx/"
              target="_blank"
              class="txt-link text-underline text-right mon-regular"
            >
              {{ texts.footer.poweredBy }}
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      //VARIABLES
      texts: "",
    };
  },
  beforeMount: function () {
    this.texts = FILE.footer[this.selectLanguage];
  },
  methods: {
    redirectLink: function () {
      let link =
        "https://proplat-public-images.s3.amazonaws.com/PROPLAT-TERMINOS_Y_CONDICIONES.pdf";
      window.open(link, "blank");
    },
    redirectFacebook: function () {
      window.open(
        "https://www.facebook.com/people/Proplat-Proplat/100073376927733/",
        "_blank"
      );
    },
    redirectInstagram: function () {
      window.open("https://www.instagram.com/proplat.inc/", "_blank");
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.footer[this.selectLanguage];
      }
    },
  },
};
</script>

<style scoped>
.text-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.bg-color {
  width: 100%;
  background: #fafbfb 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-top: 25px;
  padding-bottom: 25px;
}

.content-logo {
  width: 180px;
  height: 32px;
}

.content-logo img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-logo img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.txt-description {
  text-align: center;
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 15px;
  margin-bottom: 0;
}

.txt-link {
  text-align: left;
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 15px;
  margin-bottom: 0;
  text-transform: initial;
  text-decoration: none;
}

.txt-link:hover {
  color: #0971fb;
  text-decoration: underline;
  cursor: pointer;
}

.txt-social-media {
  letter-spacing: 0px;
  color: #6e7d94;
  opacity: 1;
  font-size: 15px;
  margin-bottom: 0;
}

.separator-line {
  border: 1px solid #bebebe;
}

@media (max-width: 600px) {
  .content-terms,
  .content-all-rights,
  .content-poweredby {
    text-align: center !important;
  }

  .content-social,
  .content-description {
    justify-content: center;
  }

  .content-image-logo {
    display: flex;
    justify-content: center;
  }
}
</style>